<template>
    <div id="services" class="cards-1 bg-gray">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Descubre todos nuestros servicios</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-icon">
                            <span class="fas fa-headphones-alt"></span>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title">Ponte en contacto con nosotros</h5>
                            <p>Y solicita tu presupuesto personalizado sin ningún compromiso. Estamos aquí para ayudarte a planificar tu mudanza de manera rápida y eficiente.</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon red">
                            <span class="far fa-clipboard"></span>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title">Analizamos cada oportunidad</h5>
                            <p>Sabemos que operamos en un mercado altamente competitivo, por eso ajustamos nuestros precios para ofrecer soluciones personalizadas y adaptadas a las necesidades de cada cliente.</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon green">
                            <span class="far fa-comments"></span>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title">Comparte tu experiencia</h5>
                            <p>Una vez finalizado el trabajo, estamos seguros de que querrás compartir la excelente experiencia que has tenido con nosotros.</p>
                        </div>
                    </div>
                  

                </div>
            </div>
        </div>
    </div>

    <div id="details" class="basic-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-xl-7">
                    <div class="image-container">
                        <img class="img-fluid images_details" src="/img/mudanza.png" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
                <div class="col-lg-6 col-xl-5">
                    <div class="text-container">
                        <div class="section-title">MUDANZAS RETO</div>
                        <h2>Mudanzas para particulares y empresas</h2>
                        <p>Entendemos que una mudanza o un traslado puede ser un proceso complejo, por eso nos comprometemos a hacerlo lo más sencillo y cómodo posible para ti.<br><br>
                        La transparencia, la flexibilidad y un trato cercano y honesto son los pilares fundamentales de nuestro compromiso con cada cliente.</p>
                        <router-link class="btn-solid-reg" to="/Mudanzas-particulares-y-empresas-Euskadi">...Más</router-link>
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-1 -->
    <!-- end of details 1 -->

    <!-- Details 2 -->
    <div class="basic-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-xl-5">
                    <div class="text-container">
                        <div class="section-title">MUDANZAS RETO</div>
                        <h2>Vaciado de pisos, garajes, naves y más</h2>
                        <p>Ofrecemos un servicio profesional de vaciado de pisos, garajes y todo tipo de inmuebles en el <b>País Vasco</b> y sus tres provincias: <b>Álava, Vizcaya y Guipúzcoa</b><br><br>
                           No importa si el inmueble está habitado o deshabitado; realizamos el trabajo de manera rápida, eficiente y sin causar molestias a vecinos o residentes.<br><br>
                           Nos aseguramos de gestionar los residuos de forma responsable, utilizando los puntos limpios más cercanos y dando una segunda vida a los objetos que puedan ser reciclados.       
                        </p>
                        <router-link class="btn-outline-reg" to="/Vaciados-de-pisos-garages-recogida-de-muebles-y-chatarra-País Vasco">...Más</router-link>
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
                <div class="col-lg-6 col-xl-7">
                    <div class="image-container">
                        <img class="img-fluid images_details2" src="/img/vaciado.png" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-2 -->
    <!-- end of details 2 -->

<div id="features" class="cards-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">

                    <div class="card">
                        <img class="img-fluid" src="/img/reformas.png" alt="Reformas Mudanzas Reto País Vasco">
                        <div class="card-body">
                            <h5 class="card-title">Reformas</h5>
                            <p class="card-text">Ofrecemos reformas de alta calidad a precios competitivos, siempre buscando las mejores soluciones para satisfacer todas tus necesidades. Nos apasiona nuestro trabajo y nuestro objetivo es asegurar la plena satisfacción de nuestros clientes
                                <router-link class="blue no-line" to="/Reformas-construccion-País Vasco">..Más</router-link></p>
                        </div>
                    </div>
                    <div class="card">
                        <img class="img-fluid" src="/img/pintura.png" alt="Pinturas Reto País Vasco">
                        <div class="card-body">
                            <h5 class="card-title">Pintura</h5>
                            <p class="card-text">Utilizamos esmaltes semi-laca sintéticos que ofrecen una excelente resistencia y elasticidad. Además, estos esmaltes proporcionan un acabado agradable al tacto y facilitan una limpieza sencilla
                                 <router-link class="blue no-line" to="/Pinturas-País Vasco">..Mas</router-link></p>
                        </div>
                    </div>
                    <div class="card">
                        <img class="img-fluid" src="/img/removal2.png" alt="Vaciados de pisos garages recogida de muebles chatarra de Reto País Vasco">
                        <div class="card-body">
                            <h5 class="card-title">Recogida de enseres</h5>
                            <p class="card-text">Ofrecemos un servicio completo de recogida de muebles, electrodomésticos y todo tipo de enseres, asegurándonos de que tus pertenencias sean gestionadas de manera responsable. Nuestro objetivo es facilitar el proceso de desalojo mientras contribuimos al reciclaje y a la reutilización de objetos en buen estado
                                <router-link class="blue no-line" to="/Vaciados-de-pisos-garages-recogida-de-muebles-y-chatarra-País Vasco">..Más</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>

 
    <div class="accordion-1">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <h2 class="h2-heading">Servicio de limpieza Mudanzas Reto</h2>
                    <p class="p-heading">En <b>Mudanzas Reto</b> ofrecemos una amplia gama de servicios para el hogar en el País Vasco, todos accesibles con una sola llamada. Nuestro servicio de limpieza destaca por su capacidad para mantener tu espacio impecable, cuando y como lo necesites. Confianza, calidad y flexibilidad son los pilares de nuestro compromiso.</p>
                </div> 
            </div> 
            <div class="row">
                <div class="col-xl-5">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Limpieza a medida para tu hogar
                                </button>
                            </div> 
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Adaptamos nuestras soluciones a tus necesidades específicas de limpieza, tanto en la ciudad como en los alrededores del <b>País Vasco</b>.                                </div>
                            </div> 
                        </div> 
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingTwo">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    Limpieza de casas afectadas por Síndrome de Diógenes
                                </button>
                            </div> 
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">En Mudanzas Reto, trabajamos para que disfrutes de unas prestaciones flexibles, con la calidad que realmente buscas para tu casa, de la manera más cómoda, sencilla y sin compromiso de permanencia.</div>
                            </div> 
                        </div> 
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingThree">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                    Limpiezas generales
                                </button>
                            </div>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">En Mudanzas Reto, no es la primera vez que tu hogar te pide una buena limpieza a fondo, con especial hincapié en estancias como los baños y la cocina, que requieren mayor cuidado relativo a la higiene. <br><br>
                                     Frente a este tipo de necesidades, ponemos a tu disposición un servicio de limpiezas generales para tu casa, chalet o adosado en Bilbao, que seguro te gustará.</div>
                            </div> 
                        </div> 
           
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingFour">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Presupuesto de limpieza sin compromiso
                                </button>
                            </div> <!-- end of accordion-header -->
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">En Mudanzas Reto, la limpieza y tareas no tienen por qué ser un problema con Mudanzas Reto en Bilbao, Vitoria-Gasteiz, San Sebastián. ¿Qué podemos ofrecerte para tu hogar? <br><br>
                                     Llámanos, cuéntanos tus necesidades y obtén un presupuesto de limpieza para tu casa en Bilbao, Vitoria-Gasteiz, San Sebastián sin compromiso.</div>
                            </div>
                        </div> 
                       
                    </div> 
                </div> 
                <div class="col-xl-7">
                    <div class="image-container">
                        <img class="img-fluid" src="/img/clean1.png" alt="Mudanzas Reto">
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
</template>

const cities = [
'San Sebastián (Donostia)',
  'Bilbao',
  'Vitoria-Gasteiz',
  'Getxo',
  'Zarautz',
  'Hondarribia',
  'Gernika-Lumo',
  'Bakio',
  'Bermeo',
  'Lekeitio',
  'Ondarroa',
  'Mundaka',
  'Rentería',
  'Tolosa',
  'Pasaia',
  'Irun',
  'Elgoibar',
  'Azpeitia',
  'Azkoitia',
  'Ordizia',
  'Urnieta',
  'Lasarte-Oria',
  'Bergara',
  'Eibar',
  'Amorebieta-Etxano',
  'Oñati',
  'Beasain',
  'Gorliz',
  'Sopelana',
  'Lemoa',
  'Mungia',
  'Erandio',
  'Balmaseda',
  'Galdakao',
  'Elorrio',
  'Zalla',
  'Trapagaran',
  'Hernani',
  'Lezo',
  'Oiarzun',
  'Aia',
  'Larrabetzu',
  'Bermeo',
  'Markina-Xemein',
  'Alegia',
  'Zegama',
  'Legorreta',
  'Mutriku',
  'Igorre',
  'Bedia',
  'Mendaro',
  'Urretxu',
  'Itziar',
  'Lazkao',
  'Aia',
  'Añorga',
  'Arrasate (Mondragón)',
  'Aretxabaleta',
  'Artea',
  'Aduna',
  'Bidegoian',
  'Beizama',
  'Ermua',
  'Galdakao',
  'Galdakao',
  'Goierri',
  'Hernani',
  'Ikaztegieta',
  'Lazkao',
  'Mendiondo',
  'Otxandio',
  'Santo Domingo',
  'Urnieta',
  'Urretxu',
  'Zarautz',
  'Zumaia',
  'Lezo',
  'Tolosa',
  'Oiartzun',
  'Zarautz',
  'Aia',
  'Azkoitia',
  'Azpeitia',
  'Getaria',
  'Gernika-Lumo'
];

const citiesSort = [
  'Bilbao',
  'Vitoria-Gasteiz',
  'San Sebastián (Donostia)',
  'Barakaldo',
  'Getxo',
  'Irun',
  'Portugalete',
  'Santurtzi',
  'Basauri',
  'Sestao',
  'Erandio',
  'Durango',
  'Leioa',
  'Eibar',
  'Hernani',
  'Arrasate (Mondragón)',
  'Galdakao',
  'Zarautz',
  'Amorebieta-Etxano',
  'Gernika-Lumo',
  'Tolosa',
  'Rentería',
  'Lasarte-Oria',
  'Llodio',
  'Bergara',
  'Pasaia',
  'Ondarroa',
  'Ortuella',
  'Elgoibar',
  'Mungia',
  'Elorrio',
  'Azpeitia',
  'Azkoitia',
  'Zalla',
  'Trapagaran',
  'Oñati',
  'Balmaseda',
  'Usurbil',
  'Sopelana',
  'Bermeo',
  'Arrigorriaga',
  'Beasain',
  'Zumarraga',
  'Zumaia',
  'Markina-Xemein',
  'Legazpi',
  'Deba',
  'Lezo',
  'Amurrio',
  'Ordizia',
  'Oiartzun',
  'Lekeitio',
  'Mutriku',
  'Etxebarri',
  'Urretxu',
  'Ormaiztegi',
  'Aretxabaleta',
  'Alegría-Dulantzi',
  'Abadiño',
  'Igorre',
  'Bedia',
  'Berriz',
  'Muxika',
  'Sukarrieta',
  'Bakio',
  'Artea',
  'Lemoa',
  'Gorliz',
  'Larrabetzu',
  'Zaratamo',
  'Ermua',
  'Leaburu',
  'Orexa',
  'Aia',
  'Errenteria',
  'Altzo',
  'Ataun',
  'Itsasondo',
  'Legorreta',
  'Segura',
  'Zegama',
  'Alkiza',
  'Alegia',
  'Tolosa',
  'Villabona',
  'Zizurkil',
  'Zarautz',
  'Hondarribia',
  'Oiartzun',
  'Orio',
  'Usurbil',
  'Lasarte-Oria',
  'Urnieta',
  'Astigarraga',
  'Andoain',
  'Anoeta',
  'Aduna',
  'Asteasu',
  'Larraul',
  'Baliarrain',
  'Altzaga',
  'Legazpi'   
];

const works = [ 'Mudanzas', 'Vaciados de pisos', 'Reformas', 'Pintura', 'Recogida muebles', 'Limpiezas' ];

export default{ cities, works, citiesSort }